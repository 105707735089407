// ************************************************************
export enum EntityState {
    NotSet = 0,
    Active = 1,
    Disabled = 2,
    Deleted = 3,
}

export type ServiceOperationStateKey = 0 | 1 | 2 | 3;
export type ServiceOperationStateValue = 'NotSet' | 'Planned' | 'Finished' | 'InProgress';

export enum ServiceOperation {
    NotSet = 0,
    Planned = 1,
    Finished = 2,
    InProgress = 3,
}

export type ComponentCategoryKey = 0 | 1 | 2 | 3 | 4;
export type ComponentCategoryValue = 'NotSet' | 'Mechanic' | 'Sensor' | 'Electronic' | 'Software';

export enum ComponentCategory {
    NotSet = 0,
    Mechanic = 1,
    Sensor = 2,
    Electronic = 3,
    Software = 4,
}

export type UserRoleKey = 0 | 1 | 2 | 3;
export type UserRoleValue = 'NotSet' | 'Admin' | 'KZV' | 'Extern';

export enum UserRole {
    NotSet = 0,
    Admin = 1,
    KZV = 2,
    Extern = 3,
}

export enum LicenseType {
    NotSet = 0,
    File = 1,
    KZV = 2,
}

export type LicenseVersion = 'unknown' | 'file' | '2.0' | '3.0' | '4.0' | '5.0';
export const LicencePrefferedVersion: LicenseVersion = '4.0';

export type ExpirationDate = Date | 'never';
export interface LicensePayloadDto { [key: string]: string };

export type ChecklistPayloadKeys = 'correctWheelsRamp' | 'smoothWheelsTurning' | 'sensorsTightening' | 'sensorsInMeasuredRanges' | 'smoothMechanicsAlign' | 'smoothMechanicsTop' | 'smoothMechanicsGauge' | 'testedInTrack' | 'spreadingForceValue' | 'notes' | 'technicianName' | 'completed' | 'completedDate';
export interface ChecklistPayloadDto { keyValues?: { [key in ChecklistPayloadKeys]?: any } };
export enum ChecklistCompletionState {
    Unknown = 0,
    Unfinshed = 1,
    Done = 2,
}

export type ModuleSubsystem = null | 'Kms' | 'Hub';

export type VgnAuth = 'None' | 'V01';

export enum Brand {
    NotSet = "",
    KZV = "kzv",
    NCCI = "ncci",
}

export interface KeyValuesDto {
    keyValues?: { [key: string]: string }
};


// ************************************************************
// auth request: login info
export interface AuthRequest {
    userName: string;
    password: string;
}

// auth refresh request: login info
export interface AuthRefreshRequest {
    refreshToken: string;
}

// auth revoke request: logout info
export interface AuthRevokeRequest {
    token: string;
}

// auth resonse user info
export interface AuthResponse {
    id?: string,
    userName?: string,
    firstName?: string,
    lastName?: string,
    role?: string,
    jwtToken?: string,
    refreshToken?: string,
}

export interface DtoBase {
    id: string; 
    createdAt?: Date;
    createdBy?: string;
    lastModifiedAt?: Date;
    lastModifiedBy?: string;
    systemState?: EntityState
}

export interface UserDto extends DtoBase {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    role: UserRole;
    password?: string;
};

export interface CreateUserDto {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    password?: string;
    role?: UserRoleKey;
};

// ************************************************************
// notes
export interface NoteDto extends DtoBase {
    text?: string;
};

export interface CreateNoteDto {
    text?: string
};

// ************************************************************
// contact
export interface ContactDto extends DtoBase {
    companyName?: string;
    personName?: string;
    city?: string;
    street?: string;
    countryCode?: string;
    phoneNumber?: string;
    email?: string;
    notes?: NoteDto[];

    // computed - for export
    countrySymbol?: string;
};

export interface CreateContactDto {
    companyName?: string;
    personName?: string;
    city?: string;
    street?: string;
    countryCode?: string;
    phoneNumber?: string;
    email?: string;
};

// **************************************************
// attached files - any attachment
export interface AttachedFileBriefDto extends DtoBase {
    fileSize?: number;
    fileName?: string;
    description?: string;
};

export interface AttachedFileDto extends AttachedFileBriefDto {
    fileContent?: string;
}


// **************************************************
// attached files - device configurations

export interface DeviceFileBriefDto extends DtoBase {
    fileName?: string;
    fileSize?: number;
    version: number;
    label?: string;
};

export interface DeviceFileDto extends DeviceFileBriefDto {
    fileContent?: string;
};


// **************************************************
// service operations

export interface CreateServiceOperationDto {
    subject?: string;
    state?: ServiceOperationStateKey;
    order?: string;
    note?: string,
    acceptedAt?: Date;
    releasedAt?: Date;
};

export interface ServiceOperationDto extends DtoBase {
    stateChangedAt?: string;
    subject?: string;
    state: ServiceOperationStateKey;
    order?: string;
    acceptedAt?: Date;
    releasedAt?: Date;
    note?: string,
    attachedFiles?: AttachedFileBriefDto[],
};

// **************************************************
// vgn board

export type VgnBoardDto = {
    serialNumber: string,
    requiredAuth: string
}

export interface VgnDto extends DtoBase {
    serialNumber?: string,
    requiredAuth?: string
    deviceName?: string,
    manufactureDate?: Date,
    radioMac?: string,
    deviceVersion?: string,
    deviceAssembly?: string,
    firmwareVersion?: string,
    deviceExpansion?: string,
    comment?: string,
};

// **************************************************
// device 

// devive type
export interface DeviceTypeDto extends DtoBase {
    name?: string;
    snPrefix?: string;
};

export interface CreateDeviceTypeDto {
    name?: string
    snPrefix?: string;
};

// **************************************************
// device components

export interface CreateComponentDto {
    serialNumber?: string;
    payload?: KeyValuesDto;
    componentPrototypeId?: string;
    note?: string,
};

export interface ComponentDto extends DtoBase {
    serialNumber?: string;
    payload?: KeyValuesDto;
    note?: string,
    vgnPayload?: VgnDto;
    componentPrototype?: ComponentPrototypeDto;
};

// device component type
export interface ComponentTypeDto extends DtoBase {
    name?: string;
    componentCategory: ComponentCategoryKey;
};

export interface CreateComponentTypeDto {
    name?: string;
    componentCategory: ComponentCategoryKey;
};

// device component product type
export interface ComponentPrototypeDto extends DtoBase {
    name?: string;
    isTemplate?: boolean,
    isVgn?: boolean,
    isAttached?: boolean,
    payload?: KeyValuesDto;
    componentType?: ComponentTypeDto;
    manufacturer?: string,
    manufacturerUrl?: string,
};

export interface CreateComponentPrototypeDto {
    componentTypeId?: string;
    name?: string;
    isTemplate?: boolean,
    payload?: KeyValuesDto;
    manufacturer?: string,
    manufacturerUrl?: string,
};


// device search query
export interface DeviceQueryDto {
    operatingSinceFrom?: string;
    operatingSinceTo?: string;
    updatedDateTimeFrom?: string;
    updatedDateTimeTo?: string;
    serviceOperationState?: ServiceOperationStateKey;
    contactName?: string;
    contactCountryCode?: string;
    deviceTypeName?: string;
    systemState?: EntityState,
    takeCount?: number,                 // takes a count of first N records
};

export interface CreateDeviceDto {
    serialNumber?: string;
    contactId?: string;
    deviceTypeId?: string;
    gaugeValues?: string;
    operatingSinceNote?: string;
    operatingSince?: Date;
    note?: string,
    externalUserId?: string,
    salesRepresentative?: string,
    sotfwareNote?: string,
    electronicNote?: string,
    mechanicNote?: string,
    sensorNote?: string,
    checklist?: ChecklistPayloadDto,
};

// device filtered
export interface DeviceBriefDto extends DtoBase {
    serialNumber?: string;
    gaugeValues?: string;
    operatingSinceNote?: string;
    operatingSince?: Date;
    contact?: ContactDto;
    deviceType?: DeviceTypeDto;
    serviceOperationCount?: number;
    deviceFileCount?: number;
    attachedFileCount?: number;
    kzvLicenseCount ?: number;
    fileLicenseCount ?: number;
    externalUserId?: string,
    note?: string,
    checklist?: ChecklistPayloadDto,

    // computed  fields
    // ------------------------------------

    // (used in grid exports)
    operatingSinceShort?: string;
};

// device dto
export interface DeviceDto extends DtoBase {
    serialNumber?: string;
    gaugeValues?: string;
    operatingSinceNote?: string;
    operatingSince?: Date;
    contact?: ContactDto;
    deviceType?: DeviceTypeDto;
    components?: ComponentDto[];
    serviceOperations?: ServiceOperationDto[];
    deviceFiles?: DeviceFileBriefDto[];
    attachedFiles?: AttachedFileBriefDto[];
    licenseFiles?: LicenseBriefDto[];
    externalUserId?: string,
    note?: string,
    salesRepresentative?: string,
    sotfwareNote?: string,
    electronicNote?: string,
    mechanicNote?: string,
    sensorNote?: string,
    checklist?: ChecklistPayloadDto,
};

// **************************************************
// Bug reports
export interface BugReportBriefDto extends DtoBase {
    description?: string;
    fileName?: string;
    resolved?: boolean;
};

export interface BugReportDto extends BugReportBriefDto {
    fileContent?: string;
};

export interface EmailMessageDto {
    from: string,
    to: string,
    subject: string,
    htmlBody: string,
}

// **************************************************
// software

export interface CreateSoftwareVersionDto {
    version?: string;
    changelog?: string;
    fileIdentifier?: string;
}
export interface SoftwareVersionDto extends CreateSoftwareVersionDto, DtoBase { }

export interface CreateSoftwareModuleDto { 
    name?: string;
    subsystem?: string;
    groupDescription?: string;
    order?: number; 
}

export interface CreateSoftwareDto {
    name?: string;
    allowVgnsConfig?: boolean;
    allowRegionsConfig?: boolean;
}

export interface SoftwareModuleDto extends CreateSoftwareModuleDto, DtoBase { }

export interface SoftwareWatcherDto extends UserDto { 
    userId: string;
}

export interface SoftwareDto extends CreateSoftwareDto, DtoBase {
    softwareVersions: SoftwareVersionDto[];
    softwareModules: SoftwareModuleDto[];
    softwareWatchers: SoftwareWatcherDto[];
}

export interface SoftwareBriefDto extends SoftwareDto { }

export interface SoftwareQueryDto {
    name?: string;
    updatedDateTimeFrom?: string;
}

// **************************************************
// licenses
export interface LicenseQueryDto {
    serialNumber?: string;
    updatedDateTimeFrom?: string;
}

export type LicenseModule = {
    name: string,
    isEnabled?: boolean,
    expiresAt?: Date
    subsystem?: string,
    groupDescription?: string,
    order?: number,
}

export type CodeDto = {
    code: string
}

export interface LicenseBaseDto extends DtoBase {
    licenseType?: LicenseType;
    internalDescription?: string;   // description for internal use only (not a part of a license file itself)

    // license
    version: LicenseVersion;        // [req] version of the license
    revision?: number;              // [opt] revision of the license, generated by serveronly, default value is 1
    expiresAt?: Date | null;        // [opt] license expiration date    
    notes?: string;                 // [opt] license notes

    // device
    deviceId?: string               // [opt] dev id - license dont have to be bound to HW    
    serialNumber?: string;          // [opt] dev serial - license dont have to be bound to HW

    // aplication
    softwareId?: string;            // [req] each license must be bound to some software  
    softwareName?: string;          // [req] shortcut (sw name)

    // brand
    brand?: string;                 // [opt] brand name or nothing (treated as KZV)

    // activation
    trialDays?: number;             // [opt] null or 0 == activated, >= 1 trial for number of days since signature date

    // owner
    ownerName: string;              // [req] license owner
    ownerCountry: string;
    ownerCountryCode: string;

    // signature
    signatureCreator?: string;
    signatureCreatedAt?: Date;
    signatureValue?: string;

    // license components and modules
    moduleValues: LicenseModule[];       

    // license regions, languages and vgns
    allowedLanguages: CodeDto[];
    allowedRegions: CodeDto[];
    allowedVgnBoards: VgnBoardDto[];
}

export interface LicenseBriefDto extends LicenseBaseDto {
    // composed license file data
    fileSize?: number;
    fileName?: string;
};

export interface CreateLicenseDto extends LicenseBaseDto {      
   

    // [file license only]
    licenseFile?: File;             
}

export interface LicenseDto extends LicenseBriefDto {
    fileContent?: string;
}