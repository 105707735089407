import React, { useEffect, useState } from 'react';

// mui
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { countries, Country } from '../model/codelist';

// styles
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
        },
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export interface Props {
    className?: string,
    label?: string,
    error?: boolean,
    helperText?: string,
    isoCode?: string;
    onChange?: (country: Country | null) => void,
}


const findByIsoCode = (isoCode?: string) => countries.find(country => country.isoCode === isoCode) ?? null;

export const CountryInput: React.FC<Props> = (props) => {

    const classes = useStyles();
    const [country, setCountry] = useState<Country | null>(findByIsoCode(props.isoCode));

    useEffect(() => {
        setCountry(findByIsoCode(props.isoCode));
    }, [props.isoCode]);

    const handleChange = (country: Country | null) => {
        const code = country == null ? '' : country.code;
        setCountry(countries.find(country => country.code === code) ?? null);
        props.onChange?.(country);
    };

    return (
        <Autocomplete
            id='country-input'
            className={props.className}
            options={countries}
            value={country}
            fullWidth
            getOptionLabel={(option) => `${option.isoCode} - ${option.name}`}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={props.label}
                    helperText={props.helperText}
                    error={props.error}
                    InputLabelProps={{ shrink: true }}
                    variant='standard'
                />}
            onChange={(_, selectedCountry) => handleChange(selectedCountry)}
        />
    )
}

export default CountryInput;