import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from '.';
import { Injections } from '../store';
import { DeviceQueryDto, DeviceBriefDto } from '../service/dataContract';
import { StoreMode, storeValue } from '../utils/storageUtils';

// utils
import { dateComparer } from '../utils/datetime';
import { resolveCountry } from '../model/codelist';
import { formatDate, formatDateTime } from '../utils/datetime';


// computes a new device fields:
// - dev.contact.countrySymbol - for expoprt
// - dev.operatingSince date - for export
function ComputeDeviceFields(payload: DeviceBriefDto[]) : DeviceBriefDto[] {

    return payload.map(dto => {

        // add recomputed country code
        if (dto.contact != null)
            dto.contact.countrySymbol = resolveCountry(dto.contact.countryCode).isoCode;
            
        dto.operatingSinceShort =  formatDate(dto.operatingSince);

        return dto;
    });        
 } 

// device store interface
export interface DeviceFilterModel {

    // loading indnpm startication
    isLoading: boolean,

    lastUpdate: Date | undefined,

    // list of devices
    devices: DeviceBriefDto[],

    // get device list async thunk
    fetchDevices: Thunk<DeviceFilterModel, { query: DeviceQueryDto }, Injections, StoreContext>,

    fetchDevicesChange: Thunk<DeviceFilterModel, void, Injections, StoreContext>,

    // stores listed devices
    storeDeviceList: Action<DeviceFilterModel, { devices: DeviceBriefDto[] }>,

    // refreshes listed devices
    refreshDeviceList: Action<DeviceFilterModel, { devices: DeviceBriefDto[] }>,

    // clears tle device list
    clear: Action<DeviceFilterModel>,

    // stores the device
    storeDevice: Action<DeviceFilterModel, { mode: StoreMode, device?: DeviceBriefDto, deviceId?: string }>,

    // set loading flag action
    setLoading: Action<DeviceFilterModel, boolean>,    
};

const deviceFilter: DeviceFilterModel = {

    /* data */

    isLoading: false,
    lastUpdate: undefined,
    devices: [],


    /* thunks */

    setLoading: action((store, payload) => { store.isLoading = payload; }),


    // fetch

    fetchDevices: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;
        actions.setLoading(true);

        await deviceClient.fetch({ query: payload.query })
            .then(devices => {
                actions.storeDeviceList({ devices: ComputeDeviceFields(devices) });
             })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to fetch device list.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    fetchDevicesChange: thunk(async (actions, payload, { injections, getStoreActions, getStoreState }) => {

        const { deviceClient } = injections;
        const lastUpdate = getStoreState().deviceFilter.lastUpdate;
        await deviceClient.fetch({ query: { updatedDateTimeFrom: lastUpdate?.toString() }})
            .then(devices => {
                actions.refreshDeviceList({ devices: ComputeDeviceFields(devices)  });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to fetch device list.`, payload: err })
            })
    }),

    storeDeviceList: action((store, payload) => { 
        store.devices = payload.devices;
        const latestChange = payload.devices.reduce((prev, current) => (dateComparer(prev.lastModifiedAt, current.lastModifiedAt)) ?  prev : current)
        store.lastUpdate = latestChange.lastModifiedAt;
    }),

    refreshDeviceList: action((store, payload) => { 

        payload.devices.forEach(dev => {
            store.devices = storeValue(store.devices ?? [], 'id', { mode:'merge', id:dev.id , item: dev });
        });

        if (payload.devices.length > 0) {
            const latestChange = payload.devices.reduce((prev, current) => (dateComparer(prev.lastModifiedAt, current.lastModifiedAt)) ?  prev : current)
            store.lastUpdate = latestChange.lastModifiedAt;
        }
    }),

    storeDevice: action((store, payload) => { store.devices = storeValue(store.devices, 'id', { mode: payload.mode, id: payload.deviceId, item: payload.device }); }),

    clear: action((store) => {
        store.devices = [];
        store.lastUpdate = undefined;
    }),
}


export default deviceFilter;
