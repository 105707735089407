import { Thunk, thunk } from 'easy-peasy';
import { ComponentCategoryKey, ComponentCategoryValue, ServiceOperationStateKey, ServiceOperationStateValue, UserRoleKey, UserRoleValue } from '../service/dataContract';
import { isType } from '../utils/typeUtils';

// lookup type definition
export type LookupType<T extends number, V> = { [key in T]: V }

// codelist item interface
export interface CodelistItem {
    id: number | string,
    value: string,
    detail?: string,
}

export interface CodelistEntry {
    key: number,
    value: string
}

const empty: CodelistItem = {
    id: -1,
    value: 'N/A',
    detail: 'MISSING'
}

// user roles
export const userRoleLookup: LookupType<UserRoleKey, UserRoleValue> = {
    0: 'NotSet',
    1: 'Admin',     // KZV admin (mporiz)
    2: 'KZV',       // writer (inrenal user)
    3: 'Extern',    // reader (externist)
}

// service op state
export const serviceStateLookup: LookupType<ServiceOperationStateKey, ServiceOperationStateValue> = {
    0: 'NotSet',
    1: 'Planned',
    2: 'Finished',
    3: 'InProgress',
};

// device component types
export const componentCategoryLookup: LookupType<ComponentCategoryKey, ComponentCategoryValue> = {
    0: 'NotSet',
    1: 'Mechanic',
    2: 'Sensor',
    3: 'Electronic',
    4: 'Software'
}

// gets the key of lookup by value
export function getLookupKey<K extends number, V>(lookup: LookupType<K, V>, value: V): K | undefined {
    const foundEntry = Object.entries(lookup).find(([k, v]) => v === value);
    if (foundEntry) {
        const key = foundEntry[0];
        if (isType<K>(key))
            return key;
    }
    return undefined;
}

// countries

export interface Country {
    isoCode: string,
    name: string,
    code: string,
    suggested?: boolean,
}

export function resolveCountry(code: number | string | undefined): Country {
    const item = countries.find(item => item.code == code);
    return item ?? { code: '0', isoCode: 'N/A', name: 'N/A' }
}

export const countries: Country[] = [
    { isoCode: 'AD', name: 'Andorra', code: '376' },
    { isoCode: 'AE', name: 'United Arab Emirates', code: '971' },
    { isoCode: 'AF', name: 'Afghanistan', code: '93' },
    { isoCode: 'AG', name: 'Antigua and Barbuda', code: '1-268' },
    { isoCode: 'AI', name: 'Anguilla', code: '1-264' },
    { isoCode: 'AL', name: 'Albania', code: '355' },
    { isoCode: 'AM', name: 'Armenia', code: '374' },
    { isoCode: 'AO', name: 'Angola', code: '244' },
    { isoCode: 'AQ', name: 'Antarctica', code: '672' },
    { isoCode: 'AR', name: 'Argentina', code: '54' },
    { isoCode: 'AS', name: 'American Samoa', code: '1-684' },
    { isoCode: 'AT', name: 'Austria', code: '43' },
    { isoCode: 'AU', name: 'Australia', code: '61', suggested: true },
    { isoCode: 'AW', name: 'Aruba', code: '297' },
    { isoCode: 'AX', name: 'Alland Islands', code: '358' },
    { isoCode: 'AZ', name: 'Azerbaijan', code: '994' },
    { isoCode: 'BA', name: 'Bosnia and Herzegovina', code: '387' },
    { isoCode: 'BB', name: 'Barbados', code: '1-246' },
    { isoCode: 'BD', name: 'Bangladesh', code: '880' },
    { isoCode: 'BE', name: 'Belgium', code: '32' },
    { isoCode: 'BF', name: 'Burkina Faso', code: '226' },
    { isoCode: 'BG', name: 'Bulgaria', code: '359' },
    { isoCode: 'BH', name: 'Bahrain', code: '973' },
    { isoCode: 'BI', name: 'Burundi', code: '257' },
    { isoCode: 'BJ', name: 'Benin', code: '229' },
    { isoCode: 'BL', name: 'Saint Barthelemy', code: '590' },
    { isoCode: 'BM', name: 'Bermuda', code: '1-441' },
    { isoCode: 'BN', name: 'Brunei Darussalam', code: '673' },
    { isoCode: 'BO', name: 'Bolivia', code: '591' },
    { isoCode: 'BR', name: 'Brazil', code: '55' },
    { isoCode: 'BS', name: 'Bahamas', code: '1-242' },
    { isoCode: 'BT', name: 'Bhutan', code: '975' },
    // { isoCode: 'BV', name: 'Bouvet Island', code: '47' }, - is norway
    { isoCode: 'BW', name: 'Botswana', code: '267' },
    { isoCode: 'BY', name: 'Belarus', code: '375' },
    { isoCode: 'BZ', name: 'Belize', code: '501' },
    { isoCode: 'CA', name: 'Canada', code: '1', suggested: true },
    { isoCode: 'CC', name: 'Cocos (Keeling) Islands', code: '61' },
    { isoCode: 'CD', name: 'Congo, Democratic Republic of the', code: '243' },
    { isoCode: 'CF', name: 'Central African Republic', code: '236' },
    { isoCode: 'CG', name: 'Congo, Republic of the', code: '242' },
    { isoCode: 'CH', name: 'Switzerland', code: '41' },
    { isoCode: 'CI', name: "Cote d'Ivoire", code: '225' },
    { isoCode: 'CK', name: 'Cook Islands', code: '682' },
    { isoCode: 'CL', name: 'Chile', code: '56' },
    { isoCode: 'CM', name: 'Cameroon', code: '237' },
    { isoCode: 'CN', name: 'China', code: '86' },
    { isoCode: 'CO', name: 'Colombia', code: '57' },
    { isoCode: 'CR', name: 'Costa Rica', code: '506' },
    { isoCode: 'CU', name: 'Cuba', code: '53' },
    { isoCode: 'CV', name: 'Cape Verde', code: '238' },
    { isoCode: 'CW', name: 'Curacao', code: '599' },
    { isoCode: 'CX', name: 'Christmas Island', code: '61' },
    { isoCode: 'CY', name: 'Cyprus', code: '357' },
    { isoCode: 'CZ', name: 'Czech Republic', code: '420' },
    { isoCode: 'DE', name: 'Germany', code: '49', suggested: true },
    { isoCode: 'DJ', name: 'Djibouti', code: '253' },
    { isoCode: 'DK', name: 'Denmark', code: '45' },
    { isoCode: 'DM', name: 'Dominica', code: '1-767' },
    { isoCode: 'DO', name: 'Dominican Republic', code: '1-809' },
    { isoCode: 'DZ', name: 'Algeria', code: '213' },
    { isoCode: 'EC', name: 'Ecuador', code: '593' },
    { isoCode: 'EE', name: 'Estonia', code: '372' },
    { isoCode: 'EG', name: 'Egypt', code: '20' },
    { isoCode: 'EH', name: 'Western Sahara', code: '212' },
    { isoCode: 'ER', name: 'Eritrea', code: '291' },
    { isoCode: 'ES', name: 'Spain', code: '34' },
    { isoCode: 'ET', name: 'Ethiopia', code: '251' },
    { isoCode: 'FI', name: 'Finland', code: '358' },
    { isoCode: 'FJ', name: 'Fiji', code: '679' },
    { isoCode: 'FK', name: 'Falkland Islands (Malvinas)', code: '500' },
    { isoCode: 'FM', name: 'Micronesia, Federated States of', code: '691' },
    { isoCode: 'FO', name: 'Faroe Islands', code: '298' },
    { isoCode: 'FR', name: 'France', code: '33', suggested: true },
    { isoCode: 'GA', name: 'Gabon', code: '241' },
    { isoCode: 'GB', name: 'United Kingdom', code: '44' },
    { isoCode: 'GD', name: 'Grenada', code: '1-473' },
    { isoCode: 'GE', name: 'Georgia', code: '995' },
    { isoCode: 'GF', name: 'French Guiana', code: '594' },
    { isoCode: 'GG', name: 'Guernsey', code: '44' },
    { isoCode: 'GH', name: 'Ghana', code: '233' },
    { isoCode: 'GI', name: 'Gibraltar', code: '350' },
    { isoCode: 'GL', name: 'Greenland', code: '299' },
    { isoCode: 'GM', name: 'Gambia', code: '220' },
    { isoCode: 'GN', name: 'Guinea', code: '224' },
    { isoCode: 'GP', name: 'Guadeloupe', code: '590' },
    { isoCode: 'GQ', name: 'Equatorial Guinea', code: '240' },
    { isoCode: 'GR', name: 'Greece', code: '30' },
    { isoCode: 'GS', name: 'South Georgia and the South Sandwich Islands', code: '500' },
    { isoCode: 'GT', name: 'Guatemala', code: '502' },
    { isoCode: 'GU', name: 'Guam', code: '1-671' },
    { isoCode: 'GW', name: 'Guinea-Bissau', code: '245' },
    { isoCode: 'GY', name: 'Guyana', code: '592' },
    { isoCode: 'HK', name: 'Hong Kong', code: '852' },
    { isoCode: 'HM', name: 'Heard Island and McDonald Islands', code: '672' },
    { isoCode: 'HN', name: 'Honduras', code: '504' },
    { isoCode: 'HR', name: 'Croatia', code: '385' },
    { isoCode: 'HT', name: 'Haiti', code: '509' },
    { isoCode: 'HU', name: 'Hungary', code: '36' },
    { isoCode: 'ID', name: 'Indonesia', code: '62' },
    { isoCode: 'IE', name: 'Ireland', code: '353' },
    { isoCode: 'IL', name: 'Israel', code: '972' },
    { isoCode: 'IM', name: 'Isle of Man', code: '44' },
    { isoCode: 'IN', name: 'India', code: '91' },
    { isoCode: 'IO', name: 'British Indian Ocean Territory', code: '246' },
    { isoCode: 'IQ', name: 'Iraq', code: '964' },
    { isoCode: 'IR', name: 'Iran, Islamic Republic of', code: '98' },
    { isoCode: 'IS', name: 'Iceland', code: '354' },
    { isoCode: 'IT', name: 'Italy', code: '39' },
    { isoCode: 'JE', name: 'Jersey', code: '44' },
    { isoCode: 'JM', name: 'Jamaica', code: '1-876' },
    { isoCode: 'JO', name: 'Jordan', code: '962' },
    { isoCode: 'JP', name: 'Japan', code: '81', suggested: true },
    { isoCode: 'KE', name: 'Kenya', code: '254' },
    { isoCode: 'KG', name: 'Kyrgyzstan', code: '996' },
    { isoCode: 'KH', name: 'Cambodia', code: '855' },
    { isoCode: 'KI', name: 'Kiribati', code: '686' },
    { isoCode: 'KM', name: 'Comoros', code: '269' },
    { isoCode: 'KN', name: 'Saint Kitts and Nevis', code: '1-869' },
    { isoCode: 'KP', name: "Korea, Democratic People's Republic of", code: '850' },
    { isoCode: 'KR', name: 'Korea, Republic of', code: '82' },
    { isoCode: 'KW', name: 'Kuwait', code: '965' },
    { isoCode: 'KY', name: 'Cayman Islands', code: '1-345' },
    { isoCode: 'KZ', name: 'Kazakhstan', code: '7' },
    { isoCode: 'LA', name: "Lao People's Democratic Republic", code: '856' },
    { isoCode: 'LB', name: 'Lebanon', code: '961' },
    { isoCode: 'LC', name: 'Saint Lucia', code: '1-758' },
    { isoCode: 'LI', name: 'Liechtenstein', code: '423' },
    { isoCode: 'LK', name: 'Sri Lanka', code: '94' },
    { isoCode: 'LR', name: 'Liberia', code: '231' },
    { isoCode: 'LS', name: 'Lesotho', code: '266' },
    { isoCode: 'LT', name: 'Lithuania', code: '370' },
    { isoCode: 'LU', name: 'Luxembourg', code: '352' },
    { isoCode: 'LV', name: 'Latvia', code: '371' },
    { isoCode: 'LY', name: 'Libya', code: '218' },
    { isoCode: 'MA', name: 'Morocco', code: '212' },
    { isoCode: 'MC', name: 'Monaco', code: '377' },
    { isoCode: 'MD', name: 'Moldova, Republic of', code: '373' },
    { isoCode: 'ME', name: 'Montenegro', code: '382' },
    { isoCode: 'MF', name: 'Saint Martin (French part)', code: '590' },
    { isoCode: 'MG', name: 'Madagascar', code: '261' },
    { isoCode: 'MH', name: 'Marshall Islands', code: '692' },
    { isoCode: 'MK', name: 'Macedonia, the Former Yugoslav Republic of', code: '389' },
    { isoCode: 'ML', name: 'Mali', code: '223' },
    { isoCode: 'MM', name: 'Myanmar', code: '95' },
    { isoCode: 'MN', name: 'Mongolia', code: '976' },
    { isoCode: 'MO', name: 'Macao', code: '853' },
    { isoCode: 'MP', name: 'Northern Mariana Islands', code: '1-670' },
    { isoCode: 'MQ', name: 'Martinique', code: '596' },
    { isoCode: 'MR', name: 'Mauritania', code: '222' },
    { isoCode: 'MS', name: 'Montserrat', code: '1-664' },
    { isoCode: 'MT', name: 'Malta', code: '356' },
    { isoCode: 'MU', name: 'Mauritius', code: '230' },
    { isoCode: 'MV', name: 'Maldives', code: '960' },
    { isoCode: 'MW', name: 'Malawi', code: '265' },
    { isoCode: 'MX', name: 'Mexico', code: '52' },
    { isoCode: 'MY', name: 'Malaysia', code: '60' },
    { isoCode: 'MZ', name: 'Mozambique', code: '258' },
    { isoCode: 'NA', name: 'Namibia', code: '264' },
    { isoCode: 'NC', name: 'New Caledonia', code: '687' },
    { isoCode: 'NE', name: 'Niger', code: '227' },
    { isoCode: 'NF', name: 'Norfolk Island', code: '672' },
    { isoCode: 'NG', name: 'Nigeria', code: '234' },
    { isoCode: 'NI', name: 'Nicaragua', code: '505' },
    { isoCode: 'NL', name: 'Netherlands', code: '31' },
    { isoCode: 'NO', name: 'Norway', code: '47' },
    { isoCode: 'NP', name: 'Nepal', code: '977' },
    { isoCode: 'NR', name: 'Nauru', code: '674' },
    { isoCode: 'NU', name: 'Niue', code: '683' },
    { isoCode: 'NZ', name: 'New Zealand', code: '64' },
    { isoCode: 'OM', name: 'Oman', code: '968' },
    { isoCode: 'PA', name: 'Panama', code: '507' },
    { isoCode: 'PE', name: 'Peru', code: '51' },
    { isoCode: 'PF', name: 'French Polynesia', code: '689' },
    { isoCode: 'PG', name: 'Papua New Guinea', code: '675' },
    { isoCode: 'PH', name: 'Philippines', code: '63' },
    { isoCode: 'PK', name: 'Pakistan', code: '92' },
    { isoCode: 'PL', name: 'Poland', code: '48' },
    { isoCode: 'PM', name: 'Saint Pierre and Miquelon', code: '508' },
    { isoCode: 'PN', name: 'Pitcairn', code: '870' },
    { isoCode: 'PR', name: 'Puerto Rico', code: '1' },
    { isoCode: 'PS', name: 'Palestine, State of', code: '970' },
    { isoCode: 'PT', name: 'Portugal', code: '351' },
    { isoCode: 'PW', name: 'Palau', code: '680' },
    { isoCode: 'PY', name: 'Paraguay', code: '595' },
    { isoCode: 'QA', name: 'Qatar', code: '974' },
    { isoCode: 'RE', name: 'Reunion', code: '262' },
    { isoCode: 'RO', name: 'Romania', code: '40' },
    { isoCode: 'RS', name: 'Serbia', code: '381' },
    { isoCode: 'RU', name: 'Russian Federation', code: '7' },
    { isoCode: 'RW', name: 'Rwanda', code: '250' },
    { isoCode: 'SA', name: 'Saudi Arabia', code: '966' },
    { isoCode: 'SB', name: 'Solomon Islands', code: '677' },
    { isoCode: 'SC', name: 'Seychelles', code: '248' },
    { isoCode: 'SD', name: 'Sudan', code: '249' },
    { isoCode: 'SE', name: 'Sweden', code: '46' },
    { isoCode: 'SG', name: 'Singapore', code: '65' },
    { isoCode: 'SH', name: 'Saint Helena', code: '290' },
    { isoCode: 'SI', name: 'Slovenia', code: '386' },
     // { isoCode: 'SJ', name: 'Svalbard and Jan Mayen', code: '47' }, norway
    { isoCode: 'SK', name: 'Slovakia', code: '421' },
    { isoCode: 'SL', name: 'Sierra Leone', code: '232' },
    { isoCode: 'SM', name: 'San Marino', code: '378' },
    { isoCode: 'SN', name: 'Senegal', code: '221' },
    { isoCode: 'SO', name: 'Somalia', code: '252' },
    { isoCode: 'SR', name: 'Suriname', code: '597' },
    { isoCode: 'SS', name: 'South Sudan', code: '211' },
    { isoCode: 'ST', name: 'Sao Tome and Principe', code: '239' },
    { isoCode: 'SV', name: 'El Salvador', code: '503' },
    { isoCode: 'SX', name: 'Sint Maarten (Dutch part)', code: '1-721' },
    { isoCode: 'SY', name: 'Syrian Arab Republic', code: '963' },
    { isoCode: 'SZ', name: 'Swaziland', code: '268' },
    { isoCode: 'TC', name: 'Turks and Caicos Islands', code: '1-649' },
    { isoCode: 'TD', name: 'Chad', code: '235' },
    { isoCode: 'TF', name: 'French Southern Territories', code: '262' },
    { isoCode: 'TG', name: 'Togo', code: '228' },
    { isoCode: 'TH', name: 'Thailand', code: '66' },
    { isoCode: 'TJ', name: 'Tajikistan', code: '992' },
    { isoCode: 'TK', name: 'Tokelau', code: '690' },
    { isoCode: 'TL', name: 'Timor-Leste', code: '670' },
    { isoCode: 'TM', name: 'Turkmenistan', code: '993' },
    { isoCode: 'TN', name: 'Tunisia', code: '216' },
    { isoCode: 'TO', name: 'Tonga', code: '676' },
    { isoCode: 'TR', name: 'Turkey', code: '90' },
    { isoCode: 'TT', name: 'Trinidad and Tobago', code: '1-868' },
    { isoCode: 'TV', name: 'Tuvalu', code: '688' },
    { isoCode: 'TW', name: 'Taiwan, Province of China', code: '886' },
    { isoCode: 'TZ', name: 'United Republic of Tanzania', code: '255' },
    { isoCode: 'UA', name: 'Ukraine', code: '380' },
    { isoCode: 'UG', name: 'Uganda', code: '256' },
    { isoCode: 'US', name: 'United States', code: '1', suggested: true },
    { isoCode: 'UY', name: 'Uruguay', code: '598' },
    { isoCode: 'UZ', name: 'Uzbekistan', code: '998' },
    { isoCode: 'VA', name: 'Holy See (Vatican City State)', code: '379' },
    { isoCode: 'VC', name: 'Saint Vincent and the Grenadines', code: '1-784' },
    { isoCode: 'VE', name: 'Venezuela', code: '58' },
    { isoCode: 'VG', name: 'British Virgin Islands', code: '1-284' },
    { isoCode: 'VI', name: 'US Virgin Islands', code: '1-340' },
    { isoCode: 'VN', name: 'Vietnam', code: '84' },
    { isoCode: 'VU', name: 'Vanuatu', code: '678' },
    { isoCode: 'WF', name: 'Wallis and Futuna', code: '681' },
    { isoCode: 'WS', name: 'Samoa', code: '685' },
    { isoCode: 'XK', name: 'Kosovo', code: '383' },
    { isoCode: 'YE', name: 'Yemen', code: '967' },
    { isoCode: 'YT', name: 'Mayotte', code: '262' },
    { isoCode: 'ZA', name: 'South Africa', code: '27' },
    { isoCode: 'ZM', name: 'Zambia', code: '260' },
    { isoCode: 'ZW', name: 'Zimbabwe', code: '263' },
];

// languages

export interface Language {
    name: string,
    code: string,    
    suggested?: boolean,
}

export function resolveLanguage(code?: string): Language {
    const lang = languages.find(item => item.code == code);
    return lang ?? { code: '0', name: 'N/A' }
}

// used languages
export const languages: Language[] = [
    { code: 'zh-CN', name: 'Chinese (Simplified, People\'s Republic of China)' },
    { code: 'cs', name: 'Czech' },
    { code: 'en', name: 'English' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'es', name: 'Spanish' },
    { code: 'pt', name: 'Portuguese' },
  ];

// all languages
/*
export const languagesFullSet: Language[] = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'af-ZA', name: 'Afrikaans (South Africa)' },
    { code: 'sq', name: 'Albanian' },
    { code: 'sq-AL', name: 'Albanian (Albania)' },
    { code: 'gsw', name: 'Alsatian' },
    { code: 'gsw-FR', name: 'Alsatian (France)' },
    { code: 'am', name: 'Amharic' },
    { code: 'am-ET', name: 'Amharic (Ethiopia)' },
    { code: 'ar', name: 'Arabic' },
    { code: 'ar-DZ', name: 'Arabic (Algeria)' },
    { code: 'ar-BH', name: 'Arabic (Bahrain)' },
    { code: 'ar-EG', name: 'Arabic (Egypt)' },
    { code: 'ar-IQ', name: 'Arabic (Iraq)' },
    { code: 'ar-JO', name: 'Arabic (Jordan)' },
    { code: 'ar-KW', name: 'Arabic (Kuwait)' },
    { code: 'ar-LB', name: 'Arabic (Lebanon)' },
    { code: 'ar-LY', name: 'Arabic (Libya)' },
    { code: 'ar-MA', name: 'Arabic (Morocco)' },
    { code: 'ar-OM', name: 'Arabic (Oman)' },
    { code: 'ar-QA', name: 'Arabic (Qatar)' },
    { code: 'ar-SA', name: 'Arabic (Saudi Arabia)' },
    { code: 'ar-SY', name: 'Arabic (Syria)' },
    { code: 'ar-TN', name: 'Arabic (Tunisia)' },
    { code: 'ar-AE', name: 'Arabic (U.A.E.)' },
    { code: 'ar-YE', name: 'Arabic (Yemen)' },
    { code: 'hy', name: 'Armenian' },
    { code: 'hy-AM', name: 'Armenian (Armenia)' },
    { code: 'as', name: 'Assamese' },
    { code: 'as-IN', name: 'Assamese (India)' },
    { code: 'az-Cyrl', name: 'Azerbaijani (Cyrillic)' },
    { code: 'az-Cyrl-AZ', name: 'Azerbaijani (Cyrillic, Azerbaijan)' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'az-Latn', name: 'Azerbaijani (Latin)' },
    { code: 'az-Latn-AZ', name: 'Azerbaijani (Latin, Azerbaijan)' },
    { code: 'bn', name: 'Bangla' },
    { code: 'bn-BD', name: 'Bangla (Bangladesh)' },
    { code: 'bn-IN', name: 'Bangla (India)' },
    { code: 'ba', name: 'Bashkir' },
    { code: 'ba-RU', name: 'Bashkir (Russia)' },
    { code: 'eu', name: 'Basque' },
    { code: 'eu-ES', name: 'Basque (Spain)' },
    { code: 'be', name: 'Belarusian' },
    { code: 'be-BY', name: 'Belarusian (Belarus)' },
    { code: 'bs-Cyrl', name: 'Bosnian (Cyrillic)' },
    { code: 'bs-Cyrl-BA', name: 'Bosnian (Cyrillic, Bosnia and Herzegovina)' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bs-Latn', name: 'Bosnian (Latin)' },
    { code: 'bs-Latn-BA', name: 'Bosnian (Latin, Bosnia and Herzegovina)' },
    { code: 'br', name: 'Breton' },
    { code: 'br-FR', name: 'Breton (France)' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'bg-BG', name: 'Bulgarian (Bulgaria)' },
    { code: 'my', name: 'Burmese' },
    { code: 'my-MM', name: 'Burmese (Myanmar)' },
    { code: 'ca', name: 'Catalan' },
    { code: 'ca-ES', name: 'Catalan (Spain)' },
    { code: 'tzm-Arab-MA', name: 'Central Atlas Tamazight (Arabic, Morocco)' },
    { code: 'ku', name: 'Central Kurdish' },
    { code: 'ku-Arab', name: 'Central Kurdish' },
    { code: 'ku-Arab-IQ', name: 'Central Kurdish (Iraq)' },
    { code: 'chr', name: 'Cherokee' },
    { code: 'chr-Cher', name: 'Cherokee' },
    { code: 'chr-Cher-US', name: 'Cherokee (United States)' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-Hans', name: 'Chinese (Simplified) (zh-Hans)' },
    { code: 'zh-CN', name: 'Chinese (Simplified, People\'s Republic of China)' },
    { code: 'zh-SG', name: 'Chinese (Simplified, Singapore)' },
    { code: 'zh-Hant', name: 'Chinese (Traditional) (zh-Hant)' },
    { code: 'zh-HK', name: 'Chinese (Traditional, Hong Kong S.A.R.)' },
    { code: 'zh-MO', name: 'Chinese (Traditional, Macao S.A.R.)' },
    { code: 'zh-TW', name: 'Chinese (Traditional, Taiwan)' },
    { code: 'co', name: 'Corsican' },
    { code: 'co-FR', name: 'Corsican (France)' },
    { code: 'hr', name: 'Croatian' },
    { code: 'hr-HR', name: 'Croatian (Croatia)' },
    { code: 'hr-BA', name: 'Croatian (Latin, Bosnia and Herzegovina)' },
    { code: 'cs', name: 'Czech' },
    { code: 'cs-CZ', name: 'Czech (Czech Republic)' },
    { code: 'da', name: 'Danish' },
    { code: 'da-DK', name: 'Danish (Denmark)' },
    { code: 'prs', name: 'Dari' },
    { code: 'prs-AF', name: 'Dari (Afghanistan)' },
    { code: 'dv', name: 'Divehi' },
    { code: 'dv-MV', name: 'Divehi (Maldives)' },
    { code: 'nl', name: 'Dutch' },
    { code: 'nl-BE', name: 'Dutch (Belgium)' },
    { code: 'nl-NL', name: 'Dutch (Netherlands)' },
    { code: 'dz-BT', name: 'Dzongkha (Bhutan)' },
    { code: 'en', name: 'English' },
    { code: 'en-AU', name: 'English (Australia)' },
    { code: 'en-BZ', name: 'English (Belize)' },
    { code: 'en-CA', name: 'English (Canada)' },
    { code: 'en-029', name: 'English (Caribbean)' },
    { code: 'en-HK', name: 'English (Hong Kong)' },
    { code: 'en-IN', name: 'English (India)' },
    { code: 'en-IE', name: 'English (Ireland)' },
    { code: 'en-JM', name: 'English (Jamaica)' },
    { code: 'en-MY', name: 'English (Malaysia)' },
    { code: 'en-NZ', name: 'English (New Zealand)' },
    { code: 'en-PH', name: 'English (Republic of the Philippines)' },
    { code: 'en-SG', name: 'English (Singapore)' },
    { code: 'en-ZA', name: 'English (South Africa)' },
    { code: 'en-TT', name: 'English (Trinidad and Tobago)' },
    { code: 'en-AE', name: 'English (United Arab Emirates)' },
    { code: 'en-GB', name: 'English (United Kingdom)' },
    { code: 'en-US', name: 'English (United States)' },
    { code: 'en-ZW', name: 'English (Zimbabwe)' },
    { code: 'et', name: 'Estonian' },
    { code: 'et-EE', name: 'Estonian (Estonia)' },
    { code: 'fo', name: 'Faroese' },
    { code: 'fo-FO', name: 'Faroese (Faroe Islands)' },
    { code: 'fil', name: 'Filipino' },
    { code: 'fil-PH', name: 'Filipino (Philippines)' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fi-FI', name: 'Finnish (Finland)' },
    { code: 'fr', name: 'French' },
    { code: 'fr-BE', name: 'French (Belgium)' },
    { code: 'fr-BJ', name: 'French (Benin)' },
    { code: 'fr-BF', name: 'French (Burkina Faso)' },
    { code: 'fr-BI', name: 'French (Burundi)' },
    { code: 'fr-CM', name: 'French (Cameroon)' },
    { code: 'fr-CA', name: 'French (Canada)' },
    { code: 'fr-CF', name: 'French (Central African Republic)' },
    { code: 'fr-TD', name: 'French (Chad)' },
    { code: 'fr-KM', name: 'French (Comoros)' },
    { code: 'fr-CG', name: 'French (Congo)' },
    { code: 'fr-CD', name: 'French (Congo, DRC)' },
    { code: 'fr-CI', name: 'French (Côte d\'Ivoire)' },
    { code: 'fr-DJ', name: 'French (Djibouti)' },
    { code: 'fr-GQ', name: 'French (Equatorial Guinea)' },
    { code: 'fr-FR', name: 'French (France)' },
    { code: 'fr-GA', name: 'French (Gabon)' },
    { code: 'fr-GP', name: 'French (Guadeloupe)' },
    { code: 'fr-GN', name: 'French (Guinea)' },
    { code: 'fr-LU', name: 'French (Luxembourg)' },
    { code: 'fr-MG', name: 'French (Madagascar)' },
    { code: 'fr-ML', name: 'French (Mali)' },
    { code: 'fr-MQ', name: 'French (Martinique)' },
    { code: 'fr-MR', name: 'French (Mauritania)' },
    { code: 'fr-MU', name: 'French (Mauritius)' },
    { code: 'fr-YT', name: 'French (Mayotte)' },
    { code: 'fr-MC', name: 'French (Monaco)' },
    { code: 'fr-NE', name: 'French (Niger)' },
    { code: 'fr-RW', name: 'French (Rwanda)' },
    { code: 'fr-RE', name: 'French (Réunion)' },
    { code: 'fr-BL', name: 'French (Saint Barthélemy)' },
    { code: 'fr-MF', name: 'French (Saint Martin)' },
    { code: 'fr-PM', name: 'French (Saint Pierre and Miquelon)' },
    { code: 'fr-SN', name: 'French (Senegal)' },
    { code: 'fr-SC', name: 'French (Seychelles)' },
    { code: 'fr-CH', name: 'French (Switzerland)' },
    { code: 'fr-TG', name: 'French (Togo)' },
    { code: 'fr-VU', name: 'French (Vanuatu)' },
    { code: 'ff', name: 'Fulah' },
    { code: 'ff-Latn', name: 'Fulah (Latin)' },
    { code: 'ff-Latn-SN', name: 'Fulah (Latin, Senegal)' },
    { code: 'gl', name: 'Galician' },
    { code: 'gl-ES', name: 'Galician (Spain)' },
    { code: 'lg', name: 'Ganda' },
    { code: 'lg-UG', name: 'Ganda (Uganda)' },
    { code: 'ka', name: 'Georgian' },
    { code: 'ka-GE', name: 'Georgian (Georgia)' },
    { code: 'de', name: 'German' },
    { code: 'de-AT', name: 'German (Austria)' },
    { code: 'de-BE', name: 'German (Belgium)' },
    { code: 'de-DE', name: 'German (Germany)' },
    { code: 'de-LI', name: 'German (Liechtenstein)' },
    { code: 'de-LU', name: 'German (Luxembourg)' },
    { code: 'de-CH', name: 'German (Switzerland)' },
    { code: 'el', name: 'Greek' },
    { code: 'el-GR', name: 'Greek (Greece)' },
    { code: 'kl', name: 'Greenlandic' },
    { code: 'kl-GL', name: 'Greenlandic (Greenland)' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'gu-IN', name: 'Gujarati (India)' },
    { code: 'ha', name: 'Hausa' },
    { code: 'ha-Latn', name: 'Hausa (Latin)' },
    { code: 'ha-Latn-NG', name: 'Hausa (Latin, Nigeria)' },
    { code: 'haw', name: 'Hawaiian' },
    { code: 'haw-US', name: 'Hawaiian (United States)' },
    { code: 'he', name: 'Hebrew' },
    { code: 'he-IL', name: 'Hebrew (Israel)' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hi-IN', name: 'Hindi (India)' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'hu-HU', name: 'Hungarian (Hungary)' },
    { code: 'is', name: 'Icelandic' },
    { code: 'is-IS', name: 'Icelandic (Iceland)' },
    { code: 'ig', name: 'Igbo' },
    { code: 'ig-NG', name: 'Igbo (Nigeria)' },
    { code: 'id', name: 'Indonesian' },
    { code: 'id-ID', name: 'Indonesian (Indonesia)' },
    { code: 'iu', name: 'Inuktitut' },
    { code: 'iu-Cans', name: 'Inuktitut (Syllabics)' },
    { code: 'iu-Cans-CA', name: 'Inuktitut (Syllabics, Canada)' },
    { code: 'iu-Latn', name: 'Inuktitut (Latin)' },
    { code: 'iu-Latn-CA', name: 'Inuktitut (Latin, Canada)' },
    { code: 'ga', name: 'Irish' },
    { code: 'ga-IE', name: 'Irish (Ireland)' },
    { code: 'xh', name: 'isiXhosa' },
    { code: 'xh-ZA', name: 'isiXhosa (South Africa)' },
    { code: 'zu', name: 'isiZulu' },
    { code: 'zu-ZA', name: 'isiZulu (South Africa)' },
    { code: 'it', name: 'Italian' },
    { code: 'it-IT', name: 'Italian (Italy)' },
    { code: 'it-CH', name: 'Italian (Switzerland)' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ja-JP', name: 'Japanese (Japan)' },
    { code: 'dyo', name: 'Jola-Fonyi' },
    { code: 'dyo-SN', name: 'Jola-Fonyi (Senegal)' },
    { code: 'kea', name: 'Kabuverdianu' },
    { code: 'kea-CV', name: 'Kabuverdianu (Cabo Verde)' },
    { code: 'kab', name: 'Kabyle' },
    { code: 'kab-DZ', name: 'Kabyle (Algeria)' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kn-IN', name: 'Kannada (India)' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'kk-KZ', name: 'Kazakh (Kazakhstan)' },
    { code: 'km', name: 'Khmer' },
    { code: 'km-KH', name: 'Khmer (Cambodia)' },
    { code: 'quc', name: 'K\'iche\'' },
    { code: 'quc-Latn', name: 'K\'iche\' (Latin)' },
    { code: 'quc-Latn-GT', name: 'K\'iche\' (Latin, Guatemala)' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'rw-RW', name: 'Kinyarwanda (Rwanda)' },
    { code: 'sw', name: 'Kiswahili' },
    { code: 'sw-KE', name: 'Kiswahili (Kenya)' },
    { code: 'sw-TZ', name: 'Kiswahili (Tanzania)' },
    { code: 'kok', name: 'Konkani' },
    { code: 'kok-IN', name: 'Konkani (India)' },
    { code: 'ko', name: 'Korean' },
    { code: 'ko-KR', name: 'Korean (Korea)' },
    { code: 'ko-KP', name: 'Korean (North Korea)' },
    { code: 'ky', name: 'Kyrgyz' },
    { code: 'ky-KG', name: 'Kyrgyz (Kyrgyzstan)' },
    { code: 'lo', name: 'Lao' },
    { code: 'lo-LA', name: 'Lao (Lao PDR)' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lv-LV', name: 'Latvian (Latvia)' },
    { code: 'ln', name: 'Lingala' },
    { code: 'ln-AO', name: 'Lingala (Angola)' },
    { code: 'ln-CF', name: 'Lingala (Central African Republic)' },
    { code: 'ln-CG', name: 'Lingala (Congo)' },
    { code: 'ln-CD', name: 'Lingala (Congo, DRC)' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lt-LT', name: 'Lithuanian (Lithuania)' },
    { code: 'nds', name: 'Low German' },
    { code: 'nds-DE', name: 'Low German (Germany)' },
    { code: 'nds-NL', name: 'Low German (Netherlands)' },
    { code: 'dsb', name: 'Lower Sorbian' },
    { code: 'dsb-DE', name: 'Lower Sorbian (Germany)' },
    { code: 'lu', name: 'Luba-Katanga' },
    { code: 'lu-CD', name: 'Luba-Katanga (Congo, DRC)' },
    { code: 'lb', name: 'Luxembourgish' },
    { code: 'lb-LU', name: 'Luxembourgish (Luxembourg)' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'mk-MK', name: 'Macedonian (North Macedonia)' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'mg-MG', name: 'Malagasy (Madagascar)' },
    { code: 'ms', name: 'Malay' },
    { code: 'ms-BN', name: 'Malay (Brunei Darussalam)' },
    { code: 'ms-MY', name: 'Malay (Malaysia)' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'ml-IN', name: 'Malayalam (India)' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mt-MT', name: 'Maltese (Malta)' },
    { code: 'gv', name: 'Manx' },
    { code: 'gv-IM', name: 'Manx (Isle of Man)' },
    { code: 'mi', name: 'Maori' },
    { code: 'mi-NZ', name: 'Maori (New Zealand)' },
    { code: 'arn', name: 'Mapudungun' },
    { code: 'arn-CL', name: 'Mapudungun (Chile)' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mr-IN', name: 'Marathi (India)' },
    { code: 'moh', name: 'Mohawk' },
    { code: 'moh-CA', name: 'Mohawk (Canada)' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'mn-MN', name: 'Mongolian (Cyrillic, Mongolia)' },
    { code: 'mn-Mong', name: 'Mongolian (Traditional)' },
    { code: 'mn-Mong-CN', name: 'Mongolian (Traditional, PRC)' },
    { code: 'ne', name: 'Nepali' },
    { code: 'ne-NP', name: 'Nepali (Nepal)' },
    { code: 'ne-IN', name: 'Nepali (India)' },
    { code: 'no', name: 'Norwegian' },
    { code: 'nb', name: 'Norwegian Bokmål' },
    { code: 'nb-NO', name: 'Norwegian Bokmål (Norway)' },
    { code: 'nn', name: 'Norwegian Nynorsk' },
    { code: 'nn-NO', name: 'Norwegian Nynorsk (Norway)' },
    { code: 'oc', name: 'Occitan' },
    { code: 'oc-FR', name: 'Occitan (France)' },
    { code: 'or', name: 'Odia' },
    { code: 'or-IN', name: 'Odia (India)' },
    { code: 'om', name: 'Oromo' },
    { code: 'om-ET', name: 'Oromo (Ethiopia)' },
    { code: 'om-KE', name: 'Oromo (Kenya)' },
    { code: 'ps', name: 'Pashto' },
    { code: 'ps-AF', name: 'Pashto (Afghanistan)' },
    { code: 'fa', name: 'Persian' },
    { code: 'fa-IR', name: 'Persian (Iran)' },
    { code: 'pl', name: 'Polish' },
    { code: 'pl-PL', name: 'Polish (Poland)' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'pt-AO', name: 'Portuguese (Angola)' },
    { code: 'pt-BR', name: 'Portuguese (Brazil)' },
    { code: 'pt-CV', name: 'Portuguese (Cabo Verde)' },
    { code: 'pt-GW', name: 'Portuguese (Guinea-Bissau)' },
    { code: 'pt-MO', name: 'Portuguese (Macao SAR)' },
    { code: 'pt-MZ', name: 'Portuguese (Mozambique)' },
    { code: 'pt-PT', name: 'Portuguese (Portugal)' },
    { code: 'pt-ST', name: 'Portuguese (São Tomé and Príncipe)' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'pa-Arab', name: 'Punjabi (Arabic)' },
    { code: 'pa-Arab-PK', name: 'Punjabi (Arabic, Pakistan)' },
    { code: 'pa-IN', name: 'Punjabi (India)' },
    { code: 'quz', name: 'Quechua' },
    { code: 'quz-BO', name: 'Quechua (Bolivia)' },
    { code: 'quz-EC', name: 'Quechua (Ecuador)' },
    { code: 'quz-PE', name: 'Quechua (Peru)' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ro-MD', name: 'Romanian (Moldova)' },
    { code: 'ro-RO', name: 'Romanian (Romania)' },
    { code: 'rm', name: 'Romansh' },
    { code: 'rm-CH', name: 'Romansh (Switzerland)' },
    { code: 'ru', name: 'Russian' },
    { code: 'ru-BY', name: 'Russian (Belarus)' },
    { code: 'ru-KZ', name: 'Russian (Kazakhstan)' },
    { code: 'ru-KG', name: 'Russian (Kyrgyzstan)' },
    { code: 'ru-MD', name: 'Russian (Moldova)' },
    { code: 'ru-RU', name: 'Russian (Russia)' },
    { code: 'ru-UA', name: 'Russian (Ukraine)' },
    { code: 'sah', name: 'Sakha' },
    { code: 'sah-RU', name: 'Sakha (Russia)' },
    { code: 'saq', name: 'Samburu' },
    { code: 'saq-KE', name: 'Samburu (Kenya)' },
    { code: 'smn', name: 'Sami (Inari)' },
    { code: 'smn-FI', name: 'Sami (Inari, Finland)' },
    { code: 'smj', name: 'Sami (Lule)' },
    { code: 'smj-SE', name: 'Sami (Lule, Sweden)' },
    { code: 'se', name: 'Sami (Northern)' },
    { code: 'se-FI', name: 'Sami (Northern, Finland)' },
    { code: 'se-NO', name: 'Sami (Northern, Norway)' },
    { code: 'se-SE', name: 'Sami (Northern, Sweden)' },
    { code: 'sms', name: 'Sami (Skolt)' },
    { code: 'sms-FI', name: 'Sami (Skolt, Finland)' },
    { code: 'sma', name: 'Sami (Southern)' },
    { code: 'sma-NO', name: 'Sami (Southern, Norway)' },
    { code: 'sma-SE', name: 'Sami (Southern, Sweden)' },
    { code: 'sg', name: 'Sango' },
    { code: 'sg-CF', name: 'Sango (Central African Republic)' },
    { code: 'sbp', name: 'Sangu' },
    { code: 'sbp-TZ', name: 'Sangu (Tanzania)' },
    { code: 'sa', name: 'Sanskrit' },
    { code: 'sa-IN', name: 'Sanskrit (India)' },
    { code: 'sc', name: 'Sardinian' },
    { code: 'sc-IT', name: 'Sardinian (Italy)' },
    { code: 'gd', name: 'Scottish Gaelic' },
    { code: 'gd-GB', name: 'Scottish Gaelic (United Kingdom)' },
    { code: 'sr', name: 'Serbian (Cyrillic)' },
    { code: 'sr-Cyrl', name: 'Serbian (Cyrillic)' },
    { code: 'sr-Cyrl-BA', name: 'Serbian (Cyrillic, Bosnia and Herzegovina)' },
    { code: 'sr-Cyrl-ME', name: 'Serbian (Cyrillic, Montenegro)' },
    { code: 'sr-Cyrl-RS', name: 'Serbian (Cyrillic, Serbia)' },
    { code: 'sr-Latn', name: 'Serbian (Latin)' },
    { code: 'sr-Latn-BA', name: 'Serbian (Latin, Bosnia and Herzegovina)' },
    { code: 'sr-Latn-ME', name: 'Serbian (Latin, Montenegro)' },
    { code: 'sr-Latn-RS', name: 'Serbian (Latin, Serbia)' },
    { code: 'nso', name: 'Sesotho sa Leboa' },
    { code: 'nso-ZA', name: 'Sesotho sa Leboa (South Africa)' },
    { code: 'tn', name: 'Setswana' },
    { code: 'tn-BW', name: 'Setswana (Botswana)' },
    { code: 'tn-ZA', name: 'Setswana (South Africa)' },
    { code: 'ksb', name: 'Shambala' },
    { code: 'ksb-TZ', name: 'Shambala (Tanzania)' },
    { code: 'sn', name: 'Shona' },
    { code: 'sn-ZW', name: 'Shona (Zimbabwe)' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'sd-Arab', name: 'Sindhi (Arabic)' },
    { code: 'sd-Arab-PK', name: 'Sindhi (Arabic, Pakistan)' },
    { code: 'si', name: 'Sinhala' },
    { code: 'si-LK', name: 'Sinhala (Sri Lanka)' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sk-SK', name: 'Slovak (Slovakia)' },    
    { code: 'sl', name: 'Slovenian' },
    { code: 'sl-SI', name: 'Slovenian (Slovenia)' },
    { code: 'so', name: 'Somali' },
    { code: 'so-DJ', name: 'Somali (Djibouti)' },
    { code: 'so-ET', name: 'Somali (Ethiopia)' },
    { code: 'so-KE', name: 'Somali (Kenya)' },
    { code: 'so-SO', name: 'Somali (Somalia)' },
    { code: 'es', name: 'Spanish' },
    { code: 'es-AR', name: 'Spanish (Argentina)' },
    { code: 'es-BZ', name: 'Spanish (Belize)' },
    { code: 'es-BO', name: 'Spanish (Bolivia)' },
    { code: 'es-BR', name: 'Spanish (Brazil)' },
    { code: 'es-CL', name: 'Spanish (Chile)' },
    { code: 'es-CO', name: 'Spanish (Colombia)' },
    { code: 'es-CR', name: 'Spanish (Costa Rica)' },
    { code: 'es-CU', name: 'Spanish (Cuba)' },
    { code: 'es-DO', name: 'Spanish (Dominican Republic)' },
    { code: 'es-EC', name: 'Spanish (Ecuador)' },
    { code: 'es-SV', name: 'Spanish (El Salvador)' },
    { code: 'es-GQ', name: 'Spanish (Equatorial Guinea)' },
    { code: 'es-GT', name: 'Spanish (Guatemala)' },
    { code: 'es-HN', name: 'Spanish (Honduras)' },
    { code: 'es-MX', name: 'Spanish (Mexico)' },
    { code: 'es-NI', name: 'Spanish (Nicaragua)' },
    { code: 'es-PA', name: 'Spanish (Panama)' },
    { code: 'es-PY', name: 'Spanish (Paraguay)' },
    { code: 'es-PE', name: 'Spanish (Peru)' },
    { code: 'es-PH', name: 'Spanish (Philippines)' },
    { code: 'es-PR', name: 'Spanish (Puerto Rico)' },
    { code: 'es-ES', name: 'Spanish (Spain)' },
    { code: 'es-US', name: 'Spanish (United States)' },
    { code: 'es-UY', name: 'Spanish (Uruguay)' },
    { code: 'es-VE', name: 'Spanish (Venezuela)' },
    { code: 'swc', name: 'Swahili (Congo)' },
    { code: 'swc-CD', name: 'Swahili (Congo DRC)' },
    { code: 'sv', name: 'Swedish' },
    { code: 'sv-AX', name: 'Swedish (Åland Islands)' },
    { code: 'sv-FI', name: 'Swedish (Finland)' },
    { code: 'sv-SE', name: 'Swedish (Sweden)' },
    { code: 'gsw', name: 'Swiss German' },
    { code: 'gsw-CH', name: 'Swiss German (Switzerland)' },
    { code: 'gsw-FR', name: 'Swiss German (France)' },
    { code: 'gsw-LI', name: 'Swiss German (Liechtenstein)' },
    { code: 'syr', name: 'Syriac' },
    { code: 'syr-SY', name: 'Syriac (Syria)' },
    { code: 'shi', name: 'Tachelhit' },
    { code: 'shi-Latn', name: 'Tachelhit (Latin)' },
    { code: 'shi-Latn-MA', name: 'Tachelhit (Latin, Morocco)' },
    { code: 'shi-Tfng', name: 'Tachelhit (Tifinagh)' },
    { code: 'shi-Tfng-MA', name: 'Tachelhit (Tifinagh, Morocco)' },
    { code: 'dav', name: 'Taita' },
    { code: 'dav-KE', name: 'Taita (Kenya)' },
    { code: 'ta', name: 'Tamil' },
    { code: 'ta-IN', name: 'Tamil (India)' },
    { code: 'ta-LK', name: 'Tamil (Sri Lanka)' },
    { code: 'ta-MY', name: 'Tamil (Malaysia)' },
    { code: 'ta-SG', name: 'Tamil (Singapore)' },
    { code: 'twq', name: 'Tasawaq' },
    { code: 'twq-NE', name: 'Tasawaq (Niger)' },
    { code: 'te', name: 'Telugu' },
    { code: 'te-IN', name: 'Telugu (India)' },
    { code: 'teo', name: 'Teso' },
    { code: 'teo-KE', name: 'Teso (Kenya)' },
    { code: 'teo-UG', name: 'Teso (Uganda)' },
    { code: 'th', name: 'Thai' },
    { code: 'th-TH', name: 'Thai (Thailand)' },
    { code: 'bo', name: 'Tibetan' },
    { code: 'bo-CN', name: 'Tibetan (China)' },
    { code: 'bo-IN', name: 'Tibetan (India)' },
    { code: 'ti', name: 'Tigrinya' },
    { code: 'ti-ER', name: 'Tigrinya (Eritrea)' },
    { code: 'ti-ET', name: 'Tigrinya (Ethiopia)' },
    { code: 'to', name: 'Tongan' },
    { code: 'to-TO', name: 'Tongan (Tonga)' },
    { code: 'tr', name: 'Turkish' },
    { code: 'tr-CY', name: 'Turkish (Cyprus)' },
    { code: 'tr-TR', name: 'Turkish (Turkey)' },
    { code: 'tk', name: 'Turkmen' },
    { code: 'tk-TM', name: 'Turkmen (Turkmenistan)' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'uk-UA', name: 'Ukrainian (Ukraine)' },
    { code: 'hsb', name: 'Upper Sorbian' },
    { code: 'hsb-DE', name: 'Upper Sorbian (Germany)' },
    { code: 'ur', name: 'Urdu' },
    { code: 'ur-IN', name: 'Urdu (India)' },
    { code: 'ur-PK', name: 'Urdu (Pakistan)' },
    { code: 'ug', name: 'Uyghur' },
    { code: 'ug-CN', name: 'Uyghur (China)' },
    { code: 'uz', name: 'Uzbek (Arabic)' },
    { code: 'uz-Arab', name: 'Uzbek (Arabic)' },
    { code: 'uz-Arab-AF', name: 'Uzbek (Arabic, Afghanistan)' },
    { code: 'uz-Cyrl', name: 'Uzbek (Cyrillic)' },
    { code: 'uz-Cyrl-UZ', name: 'Uzbek (Cyrillic, Uzbekistan)' },
    { code: 'uz-Latn', name: 'Uzbek (Latin)' },
    { code: 'uz-Latn-UZ', name: 'Uzbek (Latin, Uzbekistan)' },
    { code: 'vai', name: 'Vai (Latin)' },
    { code: 'vai-Latn', name: 'Vai (Latin)' },
    { code: 'vai-Latn-LR', name: 'Vai (Latin, Liberia)' },
    { code: 'vai-Vaii', name: 'Vai (Vai)' },
    { code: 'vai-Vaii-LR', name: 'Vai (Vai, Liberia)' },
    { code: 'ca-ES-VALENCIA', name: 'Valencian (Spain)' },
    { code: 've', name: 'Venda' },
    { code: 've-ZA', name: 'Venda (South Africa)' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'vi-VN', name: 'Vietnamese (Vietnam)' },
    { code: 'vo', name: 'Volapük' },
    { code: 'vo-001', name: 'Volapük (World)' },
    { code: 'vun', name: 'Vunjo' },
    { code: 'vun-TZ', name: 'Vunjo (Tanzania)' },
    { code: 'wae', name: 'Walser' },
    { code: 'wae-CH', name: 'Walser (Switzerland)' },
    { code: 'cy', name: 'Welsh' },
    { code: 'cy-GB', name: 'Welsh (United Kingdom)' },
    { code: 'wal', name: 'Wolaytta' },
    { code: 'wal-ET', name: 'Wolaytta (Ethiopia)' },
    { code: 'wo', name: 'Wolof' },
    { code: 'wo-SN', name: 'Wolof (Senegal)' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'xh-ZA', name: 'Xhosa (South Africa)' },
    { code: 'yav', name: 'Yangben' },
    { code: 'yav-CM', name: 'Yangben (Cameroon)' },
    { code: 'ii', name: 'Yi' },
    { code: 'ii-CN', name: 'Yi (China)' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'yo-BJ', name: 'Yoruba (Benin)' },
    { code: 'yo-NG', name: 'Yoruba (Nigeria)' },
    { code: 'dje', name: 'Zarma' },
    { code: 'dje-NE', name: 'Zarma (Niger)' },
    { code: 'zu', name: 'Zulu' },
    { code: 'zu-ZA', name: 'Zulu (South Africa)' }
  ];
  */