import React, { useEffect, useState } from 'react';

// mui
import { CardContent, Card, CardActions, List, ListItem, TextField, IconButton, CardHeader, Avatar, Tooltip, Box } from '@material-ui/core';
import MaterialTable, { Column, MTableAction } from 'material-table';
import { Theme, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../../hooks'
import { SoftwareVersionDto, SoftwareBriefDto, CreateSoftwareVersionDto, } from '../../service/dataContract';

import { validateRx, semverRxPattern } from '../../utils/validationUtils';
import { useStateValidator, ValidationResult } from '../../utils/validationHook';


import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    }
}));

interface Props {
    software?: SoftwareBriefDto;
    className?: string;    
}

interface VersionStateDto extends SoftwareVersionDto {
    state: 'created' | 'new' | 'changed',
    validation: ValidationResult,
}

interface DialogState {
    isOpen: boolean,
    data?: VersionStateDto,
    handleConfirm?: () => void,
}

const columns: Column<VersionStateDto>[] = [
    {
        title: 'Version',
        width: 50,
        field: 'version',
        validate: rowData => validateRx(rowData.version, semverRxPattern, 'use semver 2.0 syntax (1.2.3 | 1.2.3-revision)')
    },
    {
        title: 'Download Link',
        field: 'fileIdentifier',
    },
];

const VersionListEditor: React.FC<Props> = props => {

    const classes = useStyles();

    const { addSoftwareVersion, updateSoftwareVersion, deleteSoftwareVersion } = useStoreActions(state => state.software);

    const propsToState = (props: Props) => (props.software?.softwareVersions?? []).map<VersionStateDto>(ver => {
        return {
            ...ver,
            state: 'created',
            validation: { isValid: true }
        }
    });

    const [state, setState] = useState<VersionStateDto[]>(propsToState(props));
    const [deleteDialogState, setDeleteDialogState] = useState<DialogState>({ isOpen: false });

    useEffect(() => {
        setState(propsToState(props));
    }, [props.software]);


    const handleVersionCreate = (newData: CreateSoftwareVersionDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addSoftwareVersion({ softwareId: props.software?.id?? '',  version: newData});
            resolve();
        });

    const handleVersionUpdate = (newData: SoftwareVersionDto, oldData?: SoftwareVersionDto): Promise<void> =>
        new Promise((resolve) => {
            updateSoftwareVersion({ version: newData });
            resolve();
        });

    const handleVersionDelete = (oldData: SoftwareVersionDto): Promise<void> =>
        new Promise((resolve) => {
            deleteSoftwareVersion({ version: oldData });
            resolve();
        });

    return (

        <Card className={props.className}>

            <CardHeader
                avatar={
                    <Avatar>V</Avatar>
                }
                action={[]}
                title={`Manage software versions for ${props.software?.name}`}
                subheader={'List of released software versions with download link (generic url)'}
            />

            <CardContent>
            <MaterialTable
                    title="Versions"
                    isLoading={false}
                    columns={columns}
                    data={state}
                    options={{
                        addRowPosition: 'first',
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20],
                    }}

                    actions={[ ]}
                
                    editable={{
                        onRowAdd: handleVersionCreate,
                        onRowUpdate: handleVersionUpdate,
                        onRowDelete: handleVersionDelete,
                    }}
                />              
            </CardContent>
        </Card >
    );
}



export default VersionListEditor;